import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { Box, BoxProps } from '@primer/react';
import { Background } from '../../basicComponents/background/Background';
import { BallonMenu } from '../../basicComponents/accountMenu/BallonMenu';
import { selectAuth } from '../../redux/slices/auth.slice';
import { useAppSelector } from '../../redux/hooks';
import { useGetUserQuery } from '../../redux/apis/user.api';
import { TopMenu } from './TopMenu';
import { VerificationBox } from '../../basicComponents/verificationBox/VerificationBox';

const Panel = styled('div')`
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 366px;
  background-color: white;
  border-radius: 30px;
  overflow: hidden;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #dddd;
`;

const MessageBar = styled('div')`
  //min-height: fit-content;
  background: #ffe1d9;
  color: #371f57;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 3px;
  text-align: center;
  z-index: 2;
  cursor: pointer;
`;

type LayoutComposition = {
  Panel: typeof Panel;
};

type LayoutProps = BoxProps & {
  flexDirection?: 'column' | 'row';
  alignItems?: 'center' | 'start' | 'end';
  bgWatermark?: boolean;
};

export const Layout: FunctionComponent<LayoutProps> & LayoutComposition = ({
  children,
  ...props
}) => {
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const getUser = useGetUserQuery(undefined, { skip: !isLoggedIn || isGuest });
  props.flexDirection ??= 'column';
  props.alignItems ??= 'center';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '64px', width: '100%' }}>
      <Background transparent={props.bgWatermark} />
      { isLoggedIn && !isGuest ?
        <MessageBar
          // onClick={() => {
          //   dispatch(showContactForm());
          // }}
        >
          Heads up! We're rolling out an update this Sunday Feb 2nd at 5am EST that will delete all 
          game rooms. We apologize if it causes any inconvenience! Thanks for understanding 🙏
        </MessageBar> : null }
      {getUser.data && !getUser.data.emailVerified ? <VerificationBox /> : null}
      <BallonMenu />

      <TopMenu />

      <Box
        as="main"
        display="flex"
        flex={1}
        justifyContent="center"
        zIndex={1}
        {...props}
      >
        {children}
      </Box>
    </div>
  );
};

Layout.Panel = Panel;
